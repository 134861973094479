
const NEW_API_ENDPOINT = 'https://prod.restaverse.com';
// const NEW_API_ENDPOINT = 'https://dev.restaverse.com';
//const NEW_API_ENDPOINT = 'http://localhost:8000';
// const NEW_API_ENDPOINT = 'https://dev.restaverse.com';
// const NEW_API_ENDPOINT = 'https://dev.restaverse.com';
// const NEW_API_ENDPOINT = 'http://localhost:8000';
// const NEW_API_ENDPOINT = 'https://dev.restaverse.com';

export default NEW_API_ENDPOINT;
