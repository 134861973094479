import { postData, getData } from './wrapper';
import NEW_API_ENDPOINT from '@CONSTANTs/new_api_endpoint';

export function getAccountUserSettings() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/get-client-details`)
      .then((data) => {
        //resolve(data)
        if (data.message == 'Invalid token' || data.message == 'Token has expired') {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function submitAccountUserSettings(data) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/client-onboarding`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserRole(data) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/get-user-role`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAccessDetails(data) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/access-control-of-user`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAccessTokenByClientId(clientId) {
  return new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({ clientId });
    getData(`${NEW_API_ENDPOINT}/api/get-access-token-by-client-id?${queryParams}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function checkReservegoUser(token) {
  return new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({ token });
    getData(`${NEW_API_ENDPOINT}/api/reservgo/secure-endpoint?${queryParams}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/logout`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
